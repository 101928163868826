import { yupResolver } from '@hookform/resolvers/yup';
import CustomModalTwoButtons from 'components/custom-modal-two-buttons';
import EnterCustomButton from 'components/enter-custom-button';
import SimulatorDashboard from 'components/simulator-dashboard';
import { Status } from 'features/admin/dashboard/components/status';
import { StepBarFinanceProgress } from 'features/admin/stepBarFinanceProgress';
import BankAccountForm from 'features/simulator-result/dashboard/bank-account-form';
import { ReactComponent as CardSvg } from 'images/blue-card.svg';
import { ReactComponent as FraudAnalysisSvg } from 'images/blue-fraud-analysis.svg';
import { ReactComponent as GuarantorsSvg } from 'images/blue-guarantors.svg';
import { ReactComponent as ErrorOutlineSvg } from 'images/error-outline.svg';
import { ReactComponent as InfoSvg } from 'images/Information-filled.svg';
import { ReactComponent as XSvg } from 'images/x.svg';

import { AuthBiometric } from 'model/auth-biometric';
import { BankAccount } from 'model/bank-account';
import { Client } from 'model/client';
import { ClieloStatus, ContestationStatus, CreditCard } from 'model/credit-card';
import { BankAccountType } from 'model/enums/bank-account-type';
import { BiometryStatus } from 'model/enums/biometric-status';
import { ICardData } from 'model/enums/dashboard';
import DashboardCardCategory from 'model/enums/dashboard-card-category';
import FinancingStatusType from 'model/enums/financing-status-type';
import FinancingPaymentType from 'model/enums/financingPaymentType';
import { HcredFinancingStatus } from 'model/enums/hcred-financing-status';
import StepType from 'model/enums/step-type';
import SystemStepCategory from 'model/enums/system-step-category';
import { TwoFactorStatus } from 'model/enums/two-factors-status';
import TypeVerifyIdentity from 'model/enums/type-verify-identity';
import { BankAccountInFinancing, Contract, Financing } from 'model/financing';
import { Guarantor } from 'model/guarantor';
import { Insurance } from 'model/insurance';
import { useAdmin } from 'provider/admin-general';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FiCreditCard, FiGrid, FiInfo, FiKey, FiShield, FiSmile, FiStar } from 'react-icons/fi';
import { useHistory, useParams } from 'react-router-dom';
import clientService from 'services/client-service';
import creditCardService from 'services/credit-card-service';
import financingService from 'services/financing-service';
import guarantorService from 'services/guarantor-service';
import insuranceService from 'services/insurance-service';
import organizationsSystemStepsService from 'services/organization-system-steps-service';
import { stepsOrdererByStepMomentAndOrderStep } from 'shared/util/utils';
import { useTheme } from 'styled-components';
import * as yup from 'yup';
import { CardContent, CardText, GuarantorCount, IconBox } from '../../styles';
import AnalysisDatails from '../details';
import ModalAnalyzingCredit from '../modal-analyzing-credit';
import TitleHeader from '../title-header';

import SideMenuAndHeader from 'components/side-menu';
import { AnalisysInfoModal } from 'features/admin/clientData/components/analisysInfoModal';
import {
  BackGround,
  CancelAnalysisButons,
  CancelAnalysisDescription,
  CancelAnalysisDescriptionContainer,
  CancelAnalysisTitle,
  CancelAnalysisWarning,
  CancelButtonContainer,
  ClosingArea,
  ClosingIconContainer,
  DetailsContainer,
  FormContainer,
  InputContainer,
  InputContent,
  MainContent,
  ModalContainer,
  NotificationBAr,
  Overlay,
  Separator,
} from './styles';

interface StepCard extends ICardData {
  redirectTo?: string;
  redirectPayload?: unknown;
}

const excludeSystemSteps = [SystemStepCategory.SIMULATOR, SystemStepCategory.FINANCING_ANALYSIS, SystemStepCategory.SCORE_ANALYSIS];

const tabsSystemSteps = [SystemStepCategory.PAYMENT, SystemStepCategory.CONTRACT];

interface FinancingCode {
  id?: string;
  ccb?: string;
}

export interface TabSelection {
  tabNumber: number;
  tabStep?: SystemStepCategory;
}

type FinancingCodeOrNull = FinancingCode | null;
type FinanceStatusOrNull = FinancingStatusType | null;

interface Props {
  financingCodes?: FinancingCodeOrNull;
  financeStatus?: FinanceStatusOrNull;
  steps: SystemStepCategory[];
  finance?: Financing;
  verifyIdentity?: TypeVerifyIdentity;
}

interface Schema {
  choosedValue: string;
  approvedValue: string;
  installmentValue: string;
  installmentsTotal: string;
  loanGracePeriod: string;
  financingTaxInitial: string;
  interestTaxReleased: string;
  financingTax: string;
  cet: string;
  masterRetentionValue: string;
  evolveGuarantee: string;
}

const CreditAnalysisScreen = () => {
  const { color } = useTheme();
  const history = useHistory();
  const { t } = useTranslation();

  const [tabSelected, setTabSelected] = useState<TabSelection>({ tabNumber: 1 });
  const { financingId } = useParams<{ financingId: string }>();
  const [systemSteps, setSystemSteps] = useState<SystemStepCategory[]>([]);
  const [allGuarantors, setAllGuarantors] = useState<Guarantor[]>([]);
  const [bankAccountInfos, setBankAccountInfos] = useState<BankAccountInFinancing | null>(null);
  const [isShowAnalyzeFinancingModal, setIsShowAnalyzeFinancingModal] = useState<boolean>(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [financingCodes, setFinancingCodes] = useState<{ id?: string; ccb?: string } | null>(null);
  const [clientId, setClientId] = useState<number>();
  const [authBiometry, setAuthBiometry] = useState<AuthBiometric | null>(null);
  const [creditCard, setCreditCard] = useState<CreditCard | null>(null);
  const [cieloStatus, setCieloStatus] = useState<ClieloStatus>();
  const [loading, setLoading] = useState(false);
  const [financeStatus, setFinanceStatus] = useState<FinancingStatusType | null>(null);
  const [financeHcredStatus, setFinanceHcredStatus] = useState<HcredFinancingStatus | null>(null);
  const { handleSetStepFinance, stepFinance } = useAdmin();
  const theme = useTheme();

  const [isShowApproveCreditButton, setIsShowApproveCreditButton] = useState<boolean>(false);
  const [isShowSimulatorModal, setIsShowSimulatorModal] = useState<boolean>(false);
  const [isIdentityVerificationButtonLoading, setIsIdentityVerificationButtonLoading] = useState<boolean>(false);
  const [isConfirmationButtonIsLoading, setIsConfirmationButtonIsLoading] = useState<boolean>(false);

  const [isShowModalCreateBankAccount, setIsShowModalCreateBankAccount] = useState<boolean>(false);
  const [selectedBankAccount, setSelectedBankAccount] = useState<BankAccount>();
  const [isLoadingBankAccount, setIsLoadingBankAccount] = useState<boolean>(false);
  const [verifyIdentity, setVerifyIdentity] = useState<TypeVerifyIdentity>();
  const [finance, setFinance] = useState<Financing>();
  const [financingInsurance, setFinancingInsurance] = useState<Insurance>();
  const [showAnalisysInfoModal, setShowAnalisysInfoModal] = useState<boolean>(false);
  const [notificationBarMessage, setNotificationBarMessage] = useState('');
  const [notificationBarTitle, setNotificationBarTitle] = useState('');
  const [reason, setReason] = useState('');
  const [errorCancelling, setErrorCancelling] = useState(false);
  const [clientData, setClientData] = useState<Client>();
  const [contracts, setContracts] = useState<Contract[]>([]);
  const [contestationStatus, setContestationStatus] = useState<ContestationStatus>();
  const [financingIsConfirmed, setFinancingIsConfirmed] = useState<boolean>(false);

  useEffect(() => {}, [financingIsConfirmed]);

  useEffect(() => {
    HandleNotificationBarMessage();
  }, [isShowAnalyzeFinancingModal, finance?.financingStatus, financeStatus, notificationBarMessage, notificationBarTitle]);

  useEffect(() => {}, [notificationBarMessage, notificationBarTitle]);
  useEffect(() => {
    getOrganizationSteps();
    getFinancingCode();
    getAllContractsFinancing();
  }, []);

  useEffect(() => {
    if (clientId != null) {
      getClientData(clientId);
      getContestationStatus();
    }
  }, [clientId]);

  useEffect(() => {
    if (systemSteps.length > 0) {
      if (systemSteps.includes(SystemStepCategory.GUARANTORS)) {
        getAllGuarators();
      }

      if (systemSteps.includes(SystemStepCategory.BANK_ACCOUNT_DATA)) {
        getBankAccount();
      }

      if (systemSteps.includes(SystemStepCategory.FRAUD_ANALYSIS_BIOMETRY) || systemSteps.includes(SystemStepCategory.FRAUD_ANALYSIS_2FA)) {
        getAuthBiometry();
      }
    }
  }, [systemSteps]);

  useEffect(() => {
    setIsShowApproveCreditButton(false);

    if (financeStatus === FinancingStatusType.IN_PROGRESS) {
      if (
        verifyIdentity === TypeVerifyIdentity.NECESSARY &&
        systemSteps.includes(SystemStepCategory.FRAUD_ANALYSIS_BIOMETRY) &&
        systemSteps.includes(SystemStepCategory.FRAUD_ANALYSIS_2FA) &&
        authBiometry?.twoFactorAuth?.[0]?.verified &&
        authBiometry?.biometrics?.status === BiometryStatus.DONE &&
        authBiometry?.biometrics?.score != null &&
        !stepFinance?.isConfirmed
      ) {
        setIsShowApproveCreditButton(true);
      } else if (
        verifyIdentity === TypeVerifyIdentity.NECESSARY &&
        systemSteps.includes(SystemStepCategory.FRAUD_ANALYSIS_BIOMETRY) &&
        !systemSteps.includes(SystemStepCategory.FRAUD_ANALYSIS_2FA) &&
        authBiometry?.biometrics?.status === BiometryStatus.DONE &&
        authBiometry.biometrics.score != null &&
        !stepFinance?.isConfirmed
      ) {
        setIsShowApproveCreditButton(true);
      } else if (
        verifyIdentity === TypeVerifyIdentity.NECESSARY &&
        systemSteps.includes(SystemStepCategory.FRAUD_ANALYSIS_2FA) &&
        !systemSteps.includes(SystemStepCategory.FRAUD_ANALYSIS_BIOMETRY) &&
        authBiometry?.twoFactorAuth[0]?.verified &&
        !stepFinance?.isConfirmed
      ) {
        setIsShowApproveCreditButton(true);
      } else if (
        !stepFinance?.isConfirmed &&
        (verifyIdentity === TypeVerifyIdentity.IS_NOT_NECESSARY ||
          (!systemSteps.includes(SystemStepCategory.FRAUD_ANALYSIS_BIOMETRY) &&
            !systemSteps.includes(SystemStepCategory.FRAUD_ANALYSIS_2FA)))
      ) {
        setIsShowApproveCreditButton(true);
      }
    }
  }, [authBiometry, bankAccountInfos, verifyIdentity]);

  const HandleNotificationBarMessage = () => {
    switch (financeStatus) {
      case FinancingStatusType.VALIDATION:
        setNotificationBarMessage(` ${t('notificationBar.validationMessage')}`);
        setNotificationBarTitle(`${t('notificationBar.validationTitle')}`);
        break;
      case FinancingStatusType.IN_PROGRESS:
        setNotificationBarMessage(` ${t('notificationBar.inProgressMessage')}`);
        setNotificationBarTitle(`${t('notificationBar.inProgressTitle')}`);
        break;
      case FinancingStatusType.APPROVED:
        stepFinance?.bankAccount == undefined ? setNotificationBarMessage(`${t('notificationBar.bankAccountMessage')}`) : '';
        stepFinance?.bankAccount == undefined ? setNotificationBarTitle(`${t('notificationBar.bankAccountTitle')}`) : '';

        break;
    }
  };

  const getBankAccount = async () => {
    if (financingId) {
      const backAccount = await financingService.getFinancingBankAccount(Number(financingId));
      setBankAccountInfos(backAccount.data);
    }
  };

  const getIfClientNeedsToDoBiometricsOrAuthentication = (clientId: number) => {
    clientService.getIfClientNeedsToDoBiometricsOrAuthentication(clientId).then(result => setVerifyIdentity(result.isToVerifyIdentity));
  };

  useEffect(() => {
    if (systemSteps.includes(SystemStepCategory.CREDIT_CARD)) {
      getCreditCardInfos();
    }
  }, [clientId, systemSteps]);

  const getAllGuarators = async () => {
    if (financingId) {
      const guarantors = await guarantorService.getAllGuarantorsInFinancing(Number(financingId));
      setAllGuarantors(guarantors.data.guarantors);
    }
  };

  const getAuthBiometry = async () => {
    if (financingId != null) {
      const res = await financingService.getAuthenticateAndBiometry(Number(financingId));
      setAuthBiometry(res);
      setIsIdentityVerificationButtonLoading(false);
    }
  };

  const getCreditCardInfos = async () => {
    if (clientId) {
      const res = await clientService.getCreditCardFromFinancing(Number(financingId));
      setCieloStatus(res.cielo);
      setCreditCard(res.financingCreditCards?.[0]?.creditCard ?? {});
    }
  };

  const getOrganizationSteps = async () => {
    const steps = await organizationsSystemStepsService.getSteps(StepType.CCB);
    // tslint:disable-next-line: no-assign-mutated-array
    const systemStepsResponse = steps.sort(stepsOrdererByStepMomentAndOrderStep).map(step => step.systemStep.step);
    setSystemSteps(systemStepsResponse);
  };

  const getFinancingCode = async (isCheckCreditApprovalModal?: boolean) => {
    await financingService.getFinancingById(Number(financingId)).then(response => {
      setFinancingCodes({
        id: response?.identification,
        ccb: response.ccb,
      });
      setClientId(response?.client?.id);
      handleSetStepFinance(response);
      setFinance(response);

      if (response?.isInsured) {
        getFinancingInsurance(Number(financingId));
      }

      if (response?.financingStatus) {
        setFinanceStatus(response?.financingStatus);
      }
      if (response?.financingHcredStatus) {
        setFinanceHcredStatus(response?.financingHcredStatus);
      }

      if (isCheckCreditApprovalModal) {
        handleDisplayCreditApprovalModals(response.isConfirmed);
      }

      if (response?.client?.id != null && response.biometricsIsNecessary) {
        getIfClientNeedsToDoBiometricsOrAuthentication(response.client.id);
      }

      if (!response.biometricsIsNecessary) {
        setVerifyIdentity(TypeVerifyIdentity.IS_NOT_NECESSARY);
      }
    });
  };

  const getFinancingInsurance = async (financingId: number) => {
    await insuranceService.getInsuranceByFinancing(financingId).then(insurance => {
      setFinancingInsurance(insurance);
    });
  };

  const getContestationStatus = () => {
    if (clientId != null) {
      creditCardService.getContestationStatus(clientId).then(contestation => {
        const currentContestation = contestation.filter(value => value?.financing?.id === Number(financingId))[0];
        setContestationStatus(currentContestation);
      });
    }
  };

  const setStatusText2fa = () => {
    if (verifyIdentity === TypeVerifyIdentity.IS_NOT_NECESSARY) {
      return t('dashboard.authenticationNotRequired');
    } else if (verifyIdentity === TypeVerifyIdentity.NECESSARY) {
      const status = authBiometry?.twoFactorAuth?.[0]?.status;
      const verified = authBiometry?.twoFactorAuth?.[0]?.verified;

      switch (status) {
        case TwoFactorStatus.REPLIED:
          return verified ? t(`enum.authBiometryStatus.${status}.approved`) : t(`enum.authBiometryStatus.${status}.denied`);

        case TwoFactorStatus.SENT:
          return verified ? t(`enum.authBiometryStatus.${TwoFactorStatus.REPLIED}.approved`) : t(`enum.authBiometryStatus.${status}`);

        default:
          return t(`enum.authBiometryStatus.${status}`);
      }
    }
  };

  const getDashboardCard = (category: DashboardCardCategory): StepCard => {
    switch (category) {
      case DashboardCardCategory.INSURANCE:
        return {
          key: category,
          icon: (
            <IconBox>
              <FiShield size={20} color={color.primaryColor} />
            </IconBox>
          ),
          title: t('admin.customerAnalysis.stepCards.insurance'),
          content: <CardText>{t('admin.customerAnalysis.textStepCards.insurance', { ticketNo: financingInsurance?.ticketNo })}</CardText>,
          isActive: true,
          isDataFilled: true,
          hide: !finance?.isInsured,
          redirectTo: `/admin/seguro/${financingId}`,
          redirectPayload: financingInsurance,
        };
    }
  };

  const getSystemStepCard = (step: SystemStepCategory): StepCard => {
    switch (step) {
      case SystemStepCategory.GUARANTORS:
        return {
          key: step,
          icon: <GuarantorsSvg />,
          title: t('admin.customerAnalysis.stepCards.guarantors'),
          content: (
            <CardContent>
              {allGuarantors.length > 0 ? <CardText>{allGuarantors[0].name}</CardText> : <></>}
              {allGuarantors.length > 1 ? (
                <GuarantorCount>
                  <span>+{allGuarantors.length}</span>
                </GuarantorCount>
              ) : (
                <></>
              )}
            </CardContent>
          ),
          isActive: true,
          isDataFilled: false,
          redirectTo: '/admin/fiadores/1',
        };

      case SystemStepCategory.BANK_ACCOUNT_DATA:
        return {
          key: step,
          icon: <CardSvg />,
          title: t('admin.customerAnalysis.stepCards.bankAccount'),
          content:
            bankAccountInfos?.bankAccount?.pixType != null ? (
              <CardText>
                {t('admin.customerAnalysis.instantPayment', {
                  pixType: t(`enums.pixType.${bankAccountInfos.bankAccount.pixType}`),
                  pixKey: bankAccountInfos.bankAccount.pixKey,
                })}
              </CardText>
            ) : (
              <CardText>{`${bankAccountInfos?.bankAccount?.type ? BankAccountType[bankAccountInfos.bankAccount.type] : ''} ${
                bankAccountInfos?.bankAccount?.bankAccountNumber ?? '0000'
              }-${bankAccountInfos?.bankAccount?.bankAccountDigit ?? '0'}`}</CardText>
            ),
          isActive: true,
          isDataFilled: false,
          redirectTo: `/admin/conta-bancaria/${financingId}`,
        };

      case SystemStepCategory.FRAUD_ANALYSIS:
        return {
          key: step,
          icon: <FraudAnalysisSvg />,
          title: t('admin.customerAnalysis.stepCards.fraudAnalysis'),
          content: <CardText>{t('admin.customerAnalysis.textStepCards.undefined')}</CardText>,
          isActive: true,
          isDataFilled: false,
        };

      case SystemStepCategory.DOC_FRAUD_ANALYSIS:
        return {
          key: step,
          icon: <FraudAnalysisSvg />,
          title: t('admin.customerAnalysis.stepCards.fraudAnalysis'),
          content: <CardText>{t('admin.customerAnalysis.textStepCards.undefined')}</CardText>,
          isActive: true,
          isDataFilled: false,
        };

      case SystemStepCategory.FRAUD_ANALYSIS_BIOMETRY:
        return {
          key: step,
          icon: (
            <IconBox>
              <FiSmile size={20} color={color.primaryColor} />
            </IconBox>
          ),
          title: t('admin.customerAnalysis.stepCards.biometry'),
          content: (
            <CardText>
              {verifyIdentity === TypeVerifyIdentity.IS_NOT_NECESSARY
                ? t('dashboard.biometricsNotRequired')
                : t(`enum.authBiometryStatus.${authBiometry?.biometrics?.status ?? 'undefined'}`)}
            </CardText>
          ),
          isActive: true,
          isDataFilled: false,
          redirectTo: `/admin/biometria/${financingId}`,
        };

      case SystemStepCategory.FRAUD_ANALYSIS_RATINGS:
        return {
          key: step,
          icon: (
            <IconBox>
              <FiInfo size={20} color={color.primaryColor} />
            </IconBox>
          ),
          title: t('admin.customerAnalysis.stepCards.ratings'),
          content: <CardText>{t('admin.customerAnalysis.textStepCards.ratings')}</CardText>,
          isActive: true,
          isDataFilled: false,
        };

      case SystemStepCategory.FRAUD_ANALYSIS_INSIGHTS:
        return {
          key: step,
          icon: (
            <IconBox>
              <FiGrid size={20} color={color.primaryColor} />
            </IconBox>
          ),
          title: t('admin.customerAnalysis.stepCards.insights'),
          content: <CardText>{t('admin.customerAnalysis.textStepCards.insights')}</CardText>,
          isActive: true,
          isDataFilled: false,
        };

      case SystemStepCategory.FRAUD_ANALYSIS_2FA:
        return {
          key: step,
          icon: (
            <IconBox>
              <FiKey size={20} color={color.primaryColor} />
            </IconBox>
          ),
          title: t('admin.customerAnalysis.stepCards.2fa'),
          content: <CardText>{setStatusText2fa()}</CardText>,
          isActive: true,
          isDataFilled: false,
          redirectTo: `/admin/autenticacao/${financingId}`,
        };

      case SystemStepCategory.FRAUD_ANALYSIS_SCORE:
        return {
          key: step,
          icon: (
            <IconBox>
              <FiStar size={20} color={color.primaryColor} />
            </IconBox>
          ),
          title: t('admin.customerAnalysis.stepCards.score'),
          content: <CardText>{t('admin.customerAnalysis.textStepCards.score')}</CardText>,
          isActive: true,
          isDataFilled: false,
        };

      case SystemStepCategory.CREDIT_CARD:
        return {
          key: step,
          icon: (
            <IconBox>
              <FiCreditCard size={20} color={color.primaryColor} />
            </IconBox>
          ),
          title: t('admin.customerAnalysis.stepCards.creditCard'),
          content: (
            <CardContent>
              <div className="status">
                <Status text={t(`enum.filterAllStatus.${creditCard?.status}`)} color={creditCard?.status ?? 'undefined'} />
              </div>
              <CardText className="credit">{t('admin.customerAnalysis.textStepCards.creditCard', { number: creditCard?.number })}</CardText>
            </CardContent>
          ),
          isActive: true,
          isDataFilled: false,
          redirectTo: `/admin/cartao/${finance?.id}/${clientId}`,
        };

      default:
        return {
          key: step,
          icon: <FraudAnalysisSvg />,
          title: t('admin.customerAnalysis.stepCards.fraudAnalysis'),
          content: <CardText>{t('admin.customerAnalysis.textStepCards.undefined')}</CardText>,
          isActive: true,
          isDataFilled: false,
        };
    }
  };

  const closeModal = () => {
    setShowCancelModal(false);
    setLoading(false);
    setShowCancelModal(!showCancelModal);
    setErrorCancelling(false);
  };

  const cancelFinancing = async (reason: string) => {
    if (reason.length === 0) {
      setErrorCancelling(true);
      return;
    }

    if (financingId) {
      setLoading(true);
      const res = await financingService.cancelFinancing(Number(financingId), { reason });

      if (res) {
        setLoading(false);
        setShowCancelModal(false);
        history.go(0);
      }
    }
  };

  const getClientData = (clientId: number) => {
    clientService.getAllClientData(clientId).then(setClientData);
  };

  const getAllContractsFinancing = async () => {
    if (financingId) {
      const contractsRes = await financingService.getAllContractsFinancing(Number(financingId));
      setContracts(contractsRes.content);
    }
  };

  const handleDisplayCreditApprovalModals = (isFinancingConfirmed?: boolean) => {
    if (!isFinancingConfirmed) {
      setIsShowSimulatorModal(true);
    } else if (bankAccountInfos?.bankAccount == null && bankAccountInfos?.financingPaymentType === FinancingPaymentType.CLIENT) {
      setIsShowModalCreateBankAccount(true);
    }
  };

  const handleConfirmFinancing = () => {
    if (!isConfirmationButtonIsLoading) {
      setIsConfirmationButtonIsLoading(true);

      financingService.confirmFinancingByAdmin(Number(financingId)).then(() => {
        getFinancingCode(true);
        setIsShowSimulatorModal(false);
        setIsConfirmationButtonIsLoading(false);
        setIsShowApproveCreditButton(false);
        setFinancingIsConfirmed(true);
      });
    }
  };

  const cancelSchema = yup.object().shape({
    reason: yup.string().required(),
  });

  const methods = useForm({ resolver: yupResolver(cancelSchema) });

  return (
    <SideMenuAndHeader containerHeight="145" title={t('admin.dashboard.creditAnalysis')}>
      <BackGround>
        <MainContent>
          <TitleHeader
            setAuthBiometry={setAuthBiometry}
            setIsShowAnalyzeFinancingModal={setIsShowAnalyzeFinancingModal}
            setShowCancelModal={setShowCancelModal}
            setIsShowModalCreateBankAccount={setIsShowModalCreateBankAccount}
            setIsShowSimulatorModal={setIsShowSimulatorModal}
            bankAccountInfos={bankAccountInfos}
            stepFinance={stepFinance}
            isShowApproveCreditButton={isShowApproveCreditButton}
            authBiometry={authBiometry}
            verifyIdentity={verifyIdentity}
            systemSteps={systemSteps}
            financingCodes={financingCodes}
            financeStatus={financeStatus}
            clientData={clientData}
            setShowAnalisysInfoModal={setShowAnalisysInfoModal}
            showAnalisysInfoModal={showAnalisysInfoModal}
          />
          <StepBarFinanceProgress steps={systemSteps} finance={finance} verifyIdentity={verifyIdentity} />

          {notificationBarTitle.length > 0 && notificationBarMessage.length > 0 ? (
            <NotificationBAr>
              <section></section>
              <div>
                <InfoSvg />
              </div>

              <span>
                <strong>{notificationBarTitle}</strong>
                {notificationBarMessage}
              </span>
            </NotificationBAr>
          ) : (
            <></>
          )}

          <DetailsContainer>
            <AnalysisDatails contracts={contracts} />
          </DetailsContainer>

          <CancelButtonContainer containsContract={contracts.length > 0}>
            <EnterCustomButton onClick={() => setShowCancelModal(!showCancelModal)} isLoading={false} text="Cancelar análise" />
          </CancelButtonContainer>
        </MainContent>
        <ModalAnalyzingCredit showModal={isShowAnalyzeFinancingModal} closeModal={() => setIsShowAnalyzeFinancingModal(false)} />

        {showCancelModal && (
          <>
            <Overlay onClick={() => closeModal()} />
            <ModalContainer expanded={showCancelModal}>
              <ClosingArea onClick={() => closeModal()}>
                <ClosingIconContainer>
                  <XSvg />
                </ClosingIconContainer>
              </ClosingArea>
              <InputContent erroCancelling={errorCancelling}>
                <CancelAnalysisTitle> {t('admin.customerAnalysis.cancelFinancingTitle')}</CancelAnalysisTitle>
                <CancelAnalysisDescriptionContainer>
                  <CancelAnalysisDescription> {t('admin.customerAnalysis.cancelFinancingDescription')} </CancelAnalysisDescription>
                </CancelAnalysisDescriptionContainer>
                <InputContainer>
                  <FormContainer>
                    <span> {t('admin.customerAnalysis.cancelFinancingLabel')} </span>
                    <input
                      onChange={e => {
                        setReason(e.target.value);
                        setErrorCancelling(false);
                      }}
                      name="reason"
                      placeholder={
                        errorCancelling
                          ? t('landingPage.openingScreen.simulatorForm.formValidations.required')
                          : t('admin.customerAnalysis.inputs.comment')
                      }
                    />
                    {errorCancelling ? <span>{t('enum.financingStatus.Error')}</span> : <></>}
                  </FormContainer>
                </InputContainer>
                <CancelAnalysisWarning>
                  <section>
                    <ErrorOutlineSvg />
                  </section>
                  <span> Essa ação é irreversível. </span>
                </CancelAnalysisWarning>
              </InputContent>
              <Separator />

              <CancelAnalysisButons>
                <EnterCustomButton type="submit" onClick={() => cancelFinancing(reason)} isInvertColor isLoading={false} text="Confirmar" />
                <EnterCustomButton onClick={() => closeModal()} isLoading={false} text="Voltar" />
              </CancelAnalysisButons>
            </ModalContainer>
          </>
        )}

        <CustomModalTwoButtons isShowModal={isShowSimulatorModal} onCloseModal={() => setIsShowSimulatorModal(false)}>
          <div style={{ display: 'flex', minHeight: '300px' }}>
            <SimulatorDashboard
              isAdminSimulator
              handleCloseModal={handleConfirmFinancing}
              financingId={Number(financingId)}
              isConfirmationButtonIsLoading={isConfirmationButtonIsLoading}
            />
          </div>
        </CustomModalTwoButtons>

        <CustomModalTwoButtons
          large
          formId="bank-account-creation-form"
          isShowModal={isShowModalCreateBankAccount}
          onCloseModal={() => {
            setIsShowModalCreateBankAccount(false);
            setIsLoadingBankAccount(false);
          }}
          title={t('dashboard.addBankDetails')}
          children={
            <BankAccountForm
              isRegistrationByAdmin
              clientData={clientData?.physicalPerson}
              clientId={clientId}
              financingId={Number(financingId)}
              getBankAccount={() => {
                getFinancingCode();
                getBankAccount();
                getAllContractsFinancing();
              }}
              selectedBankAccount={selectedBankAccount}
              setSelectedBankAccount={setSelectedBankAccount}
              setIsShowModalCreateBankAccount={setIsShowModalCreateBankAccount}
              setIsLoadingBankAccount={setIsLoadingBankAccount}
            />
          }
          firstButtonText={t('global.button.cancel')}
          secondButtonText={selectedBankAccount?.id != null ? t('global.button.save') : t('global.button.add')}
          handleClickFirstButton={() => setIsShowModalCreateBankAccount(false)}
          loading={isLoadingBankAccount}
        />

        {showAnalisysInfoModal && clientData && <AnalisysInfoModal setShowModal={setShowAnalisysInfoModal} clientData={clientData} />}
      </BackGround>
    </SideMenuAndHeader>
  );
};

export default CreditAnalysisScreen;
